.landing-recognition {
    max-width: 100%;
    padding: 0 56px 80px;
    @media #{$medium} {
        padding: 0;
    }

    &__content {
        gap: 56px;

        & :nth-child(2) {
            flex-shrink: 2;
        }
        @media #{$large} {
            gap: 24px;
        }

        @media #{$medium} {
            flex-direction: column;
            gap: 24px;
        }
    }

    &__footer {
        @media #{$medium} {
            align-items: center;
        }

        &_buttons {
            @media #{$medium} {
                justify-content: center;
                padding-top: 32px;
            }
        }
    }

    &__loader {
        color: var(--landing-color-button);
    }

    &__checkbox-label {
        color: var(--landing-color-checkbox-label);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    &__textarea-wrapper {
        position: relative;
    }

    &__textarea {
        width: 100%;
        max-width: 720px;
        height: 260px;
        padding: 24px;
        border-color: var(--landing-color-border);
        font-size: 16px;
        line-height: 24px;
        resize: vertical;
        scroll-behavior: smooth;

        &::placeholder {
            font-family: Roboto, sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
        }

        &:focus {
            outline: none;
        }

        @media #{$large} {
            width: 100%;
        }
    }

    &__synthesis-hint {
        position: absolute;
        top: 100%;
        right: 0;
        cursor: default;
        line-height: 25px;
    }

    &__insert-text {
        position: absolute;
        top: 100%;
        left: 0;
        color: var(--landing-color-button);
        line-height: 25px;

        &:hover {
            color: var(--landing-color-button-hover);
        }
    }

    &__model-selector {
        width: 100%;
        max-width: 280px;
        height: 48px;
        line-height: 24px;
        size: 16px;

        @media #{$large} {
            max-width: 100%;
        }
    }
}
