.synthesis {
    max-width: 1400px;
    height: 100%;
    min-height: calc(100vh - 84px);
    margin: 0 auto;
    background-color: white;

    &__play-icon {
        width: 56px;
        height: 56px;
        color: #1f79f3;
    }

    :disabled &__play-icon {
        opacity: $disabledOpacity;
    }

    &__button {
        border-radius: 50%;
        background-color: #1f79f3;

        &:disabled {
            opacity: $disabledOpacity;
        }
    }

    &__icon-download {
        padding: 16px;
        color: #fff;
        font-size: 24px;
    }

    &__dropdown {
        width: 100%;
    }

    &__wrapper {
        display: grid;
        gap: 70px;
        grid-template-columns: 1fr min-content;

        @media #{$medium} {
            gap: 40px;
            grid-template-columns: 1fr;
        }
    }

    &__wrapper-dropdowns {
        display: grid;
        max-width: 800px;
        gap: 50px;
        grid-template-columns: 1fr 1fr;

        @media #{$medium} {
            gap: 24px;
            grid-template-columns: 1fr;
        }
    }

    &__buttons-wrapper {
        display: grid;
        align-items: end;
        gap: 30px;

        @media #{$medium} {
            grid-template-columns: 1fr 1fr;
            justify-items: center;
        }
    }

    &__textarea {
        position: relative;
        display: flex;
        width: 100%;
        min-height: 250px;
        flex: 1;
        padding: 48px;

        @media #{$large} {
            padding: 16px;
        }

        textarea {
            width: 100%;
            padding: 25px;
            border: 1px solid #bdbdbd;
            border-radius: 5px;
            color: #333;
            line-height: 1.3;
            resize: none;
        }
    }

    &__insert-text {
        position: absolute;
        bottom: 24px;
        left: 48px;
        color: var(--landing-color-button);
        line-height: 25px;

        &:hover {
            color: var(--landing-color-button-hover);
        }

        @media #{$large} {
            bottom: 16px;
            left: 42px;
        }
    }

    &__control {
        width: 100%;
        padding: 0 48px 48px;

        @media #{$large} {
            padding: 0 16px 16px;
        }
    }

    &__buttons {
        display: grid;
        align-items: end;
        gap: 30px;

        @media #{$medium} {
            grid-template-columns: 1fr 1fr;
            justify-items: center;
        }
    }
}

.error {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    color: red;
    font-size: 18px;
    text-align: center;
}
